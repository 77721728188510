import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { renderRoutes } from 'react-router-config'
import AlertProvider from './modules/alert/containers/AlertProvider'
import ProfileProvider from './modules/profile/containers/ProfileProvider'
import AuthProvider from './modules/security/containers/AuthProvider'
import StructureProvider from './modules/structure/containers/StructureProvider'
import SubscriptionProvider from './modules/subscription/containers/SubscriptionProvider'
import UserProvider from './modules/user/containers/UserProvider'
import routes from './routes'
import theme from './theme'

const { BrowserRouter } = require('react-router-dom')

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AlertProvider>
            <AuthProvider>
              <UserProvider>
                <StructureProvider>
                  <ProfileProvider>
                    <SubscriptionProvider>
                      {renderRoutes(routes)}
                    </SubscriptionProvider>
                  </ProfileProvider>
                </StructureProvider>
              </UserProvider>
            </AuthProvider>
          </AlertProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}

export default App
