import api from '../../api'

export const setScoreApi = ({ gameId, profilId, score }) =>
  api({
    method: 'POST',
    url: '/Games/setScore',
    data: {
      gameId,
      profilId,
      score,
    },
  })

export const getGameByIdApi = (gameId) =>
  api({
    method: 'GET',
    url: `/Games/${gameId}`,
  })
    .then(({ data }) => Promise.resolve(data))
    .catch(() => Promise.reject(new Error('Jeu non trouvé!')))
