import api from '../../api'

export const getPresignedUrlsApi = ({ fileTypes }) =>
  api({
    method: 'POST',
    url: `/Aws/ImageUploadPresignedUrls?fileType=${fileTypes}`,
  })

export const getAWSPDFUrlsApi = (fileName) =>
  api({
    method: 'POST',
    url: `/Aws/PdfDownloadPresignedUrl?fileName=${fileName}`,
  }).then((response) => Promise.resolve(response.data))

export function uploadAwsFile({ body, presignedUrl }) {
  return fetch(presignedUrl, { method: 'PUT', body }).then(async (response) => {
    const responseData = await response.text()
    return response.ok ? responseData : Promise.reject(responseData)
  })
}
