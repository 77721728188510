/* eslint-disable no-template-curly-in-string */
import {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import interceptor, { getstoredAuthInfo, storeAuthInfo } from '../interceptor'
import {
  fetchTokenApi,
  forgotPasswordApi,
  resetPasswordApi,
  changePasswordApi,
  fetchTokenBySocialLoginApi,
  registerApi,
} from '../api'
import { useAlert } from '../../alert/containers/AlertProvider'
import FullLoading from '../../../components/FullLoading'

const AuthContext = createContext()

const firebaseConfig = {
  apiKey: 'AIzaSyCLYeL3MwHExDfe0HpOpLSAdhFwYGUFTgo',
  authDomain: 'tafawak-io.firebaseapp.com',
  projectId: 'tafawak-io',
  storageBucket: 'tafawak-io.appspot.com',
  messagingSenderId: '978092095769',
  appId: '1:978092095769:web:7337ec3d2db1768314ed88',
  measurementId: '${config.measurementId}',
}

const defaultState = {
  isAuthenticated: false,
  isInternalLogin: false,
  isSync: true,
}

export default function AuthProvider(props) {
  //-----------------
  // -- states
  //-----------------
  const [autState, setAuthState] = useState(defaultState)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingType, setLoadingType] = useState()

  //-----------------
  // -- hooks
  //-----------------
  const { showAlert } = useAlert()
  const history = useHistory()

  //-----------------
  // -- Methods
  //-----------------

  const successLoginHandler = ({ tokens, provider = 'internal' }) => {
    storeAuthInfo({ ...tokens, provider })
    setAuthState((prev) => ({
      ...prev,
      isInternalLogin: provider === 'internal',
      isAuthenticated: true,
    }))
  }

  const login = useCallback(
    async ({ email, password }) => {
      try {
        setIsLoading(true)
        const { data: tokens } = await fetchTokenApi({ email, password })
        successLoginHandler({ tokens })
      } catch (error) {
        if (error.message === 'Request failed with status code 400') {
          showAlert({
            message:
              'يوجد خطأ في بيانات المستخدم، يرجى التأكد من البريد الإلكتروني وكلمة المرور',
          })
        } else {
          showAlert({ message: 'هناك خطأ ما' })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [showAlert],
  )

  const socialLogin = useCallback(
    async (provider) => {
      try {
        firebase.auth().useDeviceLanguage()

        const {
          additionalUserInfo: { providerId },
        } = await firebase.auth().signInWithPopup(provider)

        const tokenId = await firebase.auth().currentUser.getIdToken(true)
        const { data: tokens } = await fetchTokenBySocialLoginApi({ tokenId })

        successLoginHandler({ tokens, provider: providerId })
      } catch (error) {
        showAlert({ message: 'هناك خطأ ما' })
      }
    },
    [showAlert],
  )

  const facebookLogin = async () => {
    setIsLoading(true)
    setLoadingType('facebook')
    const provider = new firebase.auth.FacebookAuthProvider()
    provider.addScope('email')
    await socialLogin(provider)
    setIsLoading(false)
    setLoadingType(null)
  }

  const googleLogin = async () => {
    setIsLoading(true)
    setLoadingType('google')
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    await socialLogin(provider)
    setIsLoading(false)
    setLoadingType(null)
  }

  const twitterLogin = async () => {
    setIsLoading(true)
    setLoadingType('twitter')
    const provider = new firebase.auth.TwitterAuthProvider()
    await socialLogin(provider)
    setIsLoading(false)
    setLoadingType(null)
  }

  const forgotPassword = useCallback(
    async ({ email }) => {
      try {
        setIsLoading(true)
        await forgotPasswordApi({ email })
        history.replace('/login')
        showAlert({
          message:
            '   سيتم إرسال رسالة لكم في البريد الإلكتروني لتغيير كلمة المرور',
          variant: 'success',
        })
      } catch (error) {
        showAlert({ message: 'هناك خطأ ما' })
      } finally {
        setIsLoading(false)
      }
    },
    [history, showAlert],
  )

  const resetPassword = useCallback(
    async ({ email, password, code }) => {
      try {
        if (!email || !code) {
          throw new Error('email and code is required')
        }
        setIsLoading(true)
        await resetPasswordApi({ email, password, code })
        history.replace('/login')
        showAlert({
          message: 'تم تغيير كلمة المرور بنجاح',
          variant: 'success',
        })
      } catch (error) {
        showAlert({ message: 'هناك خطأ ما' })
      } finally {
        setIsLoading(false)
      }
    },
    [history, showAlert],
  )

  const changePassword = useCallback(
    async ({ oldPassword, newPassword }) => {
      try {
        setIsLoading(true)
        await changePasswordApi({ oldPassword, newPassword })
        showAlert({ message: 'تم تغيير كلمة المرور', variant: 'success' })
        history.replace('/')
      } catch (error) {
        if (error.message === 'Request failed with status code 400') {
          showAlert({
            message:
              'يرجى خطأ في بيانات المستخدم، يرجى التأكد من البريد الإلكتروني وكلمة المرور',
          })
        } else {
          showAlert({ message: 'هناك خطأ ما' })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [history, showAlert],
  )

  const logout = useCallback(
    async (isSessionExpired = false) => {
      try {
        // logout firebase user if exist
        const firebaseUser = await firebase.auth().currentUser
        if (firebaseUser) {
          await firebase.auth().signOut()
        }
        localStorage.clear()

        if (isSessionExpired) {
          showAlert({ message: 'انتهت صلاحية الجلسة!' })
        }

        setAuthState((prev) => ({
          ...prev,
          isAuthenticated: false,
          isInternalLogin: false,
        }))
      } catch (error) {
        showAlert({ message: 'هناك خطأ ما' })
      }
    },
    [showAlert],
  )

  const register = async ({ email, password }) => {
    try {
      setIsLoading(true)
      await registerApi({ email, password })
      history.replace('/')
      showAlert({
        message:
          'تسجيل ناجح، سيتم إرسال رسالة إلى بريدكم الإلكتروني لتأكيد حسابكم على منصة تفوق ',
        variant: 'success',
      })
    } catch (error) {
      showAlert({
        message:
          'البريد الإلكتروني مستعمل من قبل، يبدو أنك قمت بالتسجيل على منصة تفوق من قبل، إذا كنت قد نسيت كلمة المرور يمكنك استرجاعها بالضعط على نسيت كلمة المرور',
      })
    } finally {
      setIsLoading(false)
    }
  }

  //-----------------
  // -- effect
  //-----------------

  // sync tokens
  useEffect(() => {
    const data = getstoredAuthInfo()
    const isInternalLogin = data && data.provider === 'internal'
    setAuthState((prev) => ({
      ...prev,
      isAuthenticated: Boolean(data),
      isInternalLogin,
      isSync: false,
    }))
  }, [])

  // init interceptor
  useEffect(() => {
    const removeIntereptor = interceptor({ logout })
    return removeIntereptor
  }, [logout])

  // init firebase
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
      firebase.analytics()
    }
  }, [])

  const contextValues = {
    isAuthenticated: autState.isAuthenticated,
    isInternalLogin: autState.isInternalLogin,
    isLoading,
    logout,
    forgotPassword,
    resetPassword,
    changePassword,
    login,
    facebookLogin,
    googleLogin,
    twitterLogin,
    register,
    loadingType,
  }

  if (autState.isSync) {
    return <FullLoading />
  }

  return <AuthContext.Provider value={contextValues} {...props} />
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}
