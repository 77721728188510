/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
const deepCopy = (inObject) => {
  let value
  let key

  let outObject

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}
  // eslint-disable-next-line no-restricted-syntax
  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value)
  }

  return outObject
}

export const createTree = (data = []) => {
  const idMapping = data.reduce((acc, el, i) => {
    acc[el.id] = i
    return acc
  }, {})

  let root
  const dataCopy = deepCopy(data)
  dataCopy.forEach((el) => {
    // Handle the root element
    if (!Object.prototype.hasOwnProperty.call(el, 'parentId')) {
      root = el
      return
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = dataCopy[idMapping[el.parentId]]
    // Add our current el to its parent's `children` array
    parentEl.children = [...(parentEl.children || []), el]
  })

  return root
}
