import PropTypes from 'prop-types'

function LogoIcon(props) {
  const { width = 160, height = 80 } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 160 80"
    >
      <g>
        <g transform="translate(-68.777 -52.26)">
          <g transform="translate(68.779 52.843)">
            <g transform="translate(0 12.123)">
              <path
                d="M64.526,115.925A4.2,4.2,0,0,0,67.49,117.1h.088a3.974,3.974,0,0,0,2.824-1.183,4.039,4.039,0,0,0,1.2-2.953,4.225,4.225,0,0,0-1.141-2.971,3.9,3.9,0,0,0-2.955-1.286h-.016a4.146,4.146,0,0,0-2.975,1.266,4.088,4.088,0,0,0-1.244,2.993,3.986,3.986,0,0,0,1.253,2.958Z"
                transform="translate(-49.387 -103.449)"
                fill="#3b448e"
              />
              <path
                d="M59.9,136.287h0c-.791-.093-1.827-.179-3.079-.256s-2.362-.117-3.293-.117a13.032,13.032,0,0,0-6.263,1.271,7.217,7.217,0,0,0-3.327,4.141A23.793,23.793,0,0,0,43,148.758a22.893,22.893,0,0,0,.807,6.921,5.808,5.808,0,0,0,2.755,3.505,11.051,11.051,0,0,0,4.987.94h5.243v.41a7.259,7.259,0,0,1-1.428,4.725A5.279,5.279,0,0,1,51,166.946H39.394a5.219,5.219,0,0,1-4.338-1.684,7.412,7.412,0,0,1-1.41-4.774A36.328,36.328,0,0,1,34,156.333c.236-1.852.5-3.816.776-5.832l.056-.374a2.269,2.269,0,0,0-1.87-2.576l-.4-.069a2.29,2.29,0,0,0-2.617,1.9,87.356,87.356,0,0,0-1.324,11.842,12.187,12.187,0,0,0,1.214,5.513A9.013,9.013,0,0,0,33.5,170.6a11.526,11.526,0,0,0,5.808,1.389H51.126a11.659,11.659,0,0,0,5.823-1.385,9.131,9.131,0,0,0,3.717-3.862,11.925,11.925,0,0,0,1.238-5.472V138.553A2.285,2.285,0,0,0,59.9,136.287Zm-3.108,17.63a1.162,1.162,0,0,1-1.162,1.162h-2.9a6.43,6.43,0,0,1-2.817-.495,2.8,2.8,0,0,1-1.319-1.733,14.214,14.214,0,0,1-.475-4.232,16.166,16.166,0,0,1,.541-4.7,3.848,3.848,0,0,1,1.685-2.274,6.913,6.913,0,0,1,3.479-.73h.006c.512,0,1.146.028,1.883.084a1.174,1.174,0,0,1,1.083,1.161Z"
                transform="translate(-28.617 -119.753)"
                fill="#3b448e"
              />
              <path
                d="M87.173,115.925a4.2,4.2,0,0,0,2.964,1.177h.088a3.974,3.974,0,0,0,2.824-1.183,4.039,4.039,0,0,0,1.2-2.953,4.225,4.225,0,0,0-1.141-2.971,3.9,3.9,0,0,0-2.955-1.286h-.016a4.146,4.146,0,0,0-2.975,1.266,4.088,4.088,0,0,0-1.244,2.993A3.987,3.987,0,0,0,87.173,115.925Z"
                transform="translate(-62.959 -103.449)"
                fill="#3b448e"
              />
              <path
                d="M135.447,114.872a2.184,2.184,0,0,0,1.787.739,2.3,2.3,0,0,0,2.481-1.571,1.554,1.554,0,0,0,1.346.74,1.8,1.8,0,0,0,.462-.046l1.877-.384a.757.757,0,0,0,.605-.741v-2.477q0-.973-.112-2.1a.77.77,0,0,0-.015-.094h0a.761.761,0,0,0-.906-.58l-.227.051a.757.757,0,0,0-.59.738v3.414l-.84.185a.887.887,0,0,1-.758-.161.8.8,0,0,1-.336-.671v-1.282a.747.747,0,0,0-.017-.157.757.757,0,0,0-1.5.157v1.282a2.237,2.237,0,0,1-.273,1.154,1,1,0,0,1-.821.508.641.641,0,0,1-.714-.277,1.873,1.873,0,0,1-.252-1.062v-1.533a.757.757,0,0,0-.935-.736l-.258.062a.747.747,0,0,0-.579.682q-.038.658-.038,1.71a3.861,3.861,0,0,0,.61,2.448Z"
                transform="translate(-92.272 -103.231)"
                fill="#3b448e"
              />
              <path
                d="M135.754,99.776l7.653-1.657a.757.757,0,0,0,.6-.74V96.342a.757.757,0,0,0-.917-.74l-7.652,1.658a.757.757,0,0,0-.6.739v1.038a.755.755,0,0,0,.917.74Z"
                transform="translate(-92.272 -95.585)"
                fill="#3b448e"
              />
              <path
                d="M207.375,115.639a4.2,4.2,0,0,0,2.964,1.177h.088a3.973,3.973,0,0,0,2.824-1.183,4.038,4.038,0,0,0,1.2-2.953,4.225,4.225,0,0,0-1.142-2.971,3.9,3.9,0,0,0-2.954-1.286h-.016a4.146,4.146,0,0,0-2.975,1.266,4.088,4.088,0,0,0-1.244,2.993A3.987,3.987,0,0,0,207.375,115.639Z"
                transform="translate(-134.993 -103.277)"
                fill="#3b448e"
              />
              <path
                d="M241.206,134.6h-.633a2.243,2.243,0,0,0-2.24,2.2v15.841a1.123,1.123,0,0,1-1.121,1.121H167.555a1.122,1.122,0,0,1-1.12-1.12V137.2a2.246,2.246,0,0,0-1.968-2.225,50.016,50.016,0,0,0-5.992-.327,15.079,15.079,0,0,0-6.4,1.118,7.012,7.012,0,0,0-3.495,3.875,20.336,20.336,0,0,0-1.05,7.3,29.726,29.726,0,0,0,.438,5.492,1.118,1.118,0,0,1-1.1,1.336H140.1a1.123,1.123,0,0,1-1.121-1.12v-16.01a2.231,2.231,0,0,0-1.834-2.209c-.8-.14-1.733-.275-2.754-.4a38.3,38.3,0,0,0-4.508-.305,10.659,10.659,0,0,0-5.746,1.358,7.592,7.592,0,0,0-3.092,4.3,26.39,26.39,0,0,0-.93,7.465,19.763,19.763,0,0,0,1.588,8.365c1.118,2.39,3.446,3.6,6.917,3.6h4.037a1.13,1.13,0,0,1,1.124,1.139.6.6,0,0,1,0,.062,13.719,13.719,0,0,1-.4,2.867,3.157,3.157,0,0,1-1.1,1.872,3.78,3.78,0,0,1-2.015.469,30.384,30.384,0,0,1-4.32-.409,2.242,2.242,0,0,0-2.589,2.21v.5a2.262,2.262,0,0,0,1.933,2.218,43.226,43.226,0,0,0,5.27.531,6.667,6.667,0,0,0,6.224-3.281,17.824,17.824,0,0,0,2-7.167,1.114,1.114,0,0,1,1.111-1.007H241.206a2.245,2.245,0,0,0,2.241-2.238V136.843a2.245,2.245,0,0,0-2.241-2.241Zm-79.887,6.172v11.871a1.122,1.122,0,0,1-1.12,1.121h-5.722a1.116,1.116,0,0,1-1.046-.713,11.022,11.022,0,0,1-.426-1.378,19.946,19.946,0,0,1-.362-4.415,18.351,18.351,0,0,1,.482-4.786,3.582,3.582,0,0,1,1.558-2.214,7.053,7.053,0,0,1,3.538-.7c.451,0,1.14.034,2.051.1a1.133,1.133,0,0,1,1.047,1.119Zm-27.456-.766v12.637a1.123,1.123,0,0,1-1.121,1.121h-2.819c-1.939,0-3.162-.6-3.739-1.826a12.424,12.424,0,0,1-.956-5.228c.026-2.922.454-5.016,1.271-6.226.78-1.154,2.175-1.716,4.263-1.717a20.081,20.081,0,0,1,2.091.12,1.129,1.129,0,0,1,1.009,1.119Z"
                transform="translate(-83.449 -118.441)"
                fill="#3b448e"
              />
            </g>
            <g transform="translate(61.734 58.933)">
              <path
                d="M197.787,214.4a1.563,1.563,0,0,1-1.547,1.579h-4.457v14.934a1.566,1.566,0,0,1-3.131.027v-14.96h-4.426a1.566,1.566,0,1,1,0-3.131h12.009A1.557,1.557,0,0,1,197.787,214.4Z"
                transform="translate(-182.672 -212.668)"
                fill="#6d9dab"
              />
              <path
                d="M227.183,230.923v9.912a1.482,1.482,0,0,1-1.485,1.475h-.016a1.374,1.374,0,0,1-1.372-1.475v-.311a5.905,5.905,0,0,1-3.96,1.527,6.25,6.25,0,1,1,0-12.5,6.146,6.146,0,0,1,3.96,1.5v-.129a1.374,1.374,0,0,1,1.372-1.475,1.482,1.482,0,0,1,1.5,1.459Zm-3.6,4.893a3.248,3.248,0,1,0-3.235,3.209,3.248,3.248,0,0,0,3.235-3.209Z"
                transform="translate(-201.507 -222.615)"
                fill="#6d9dab"
              />
              <path
                d="M261.54,215.995a1.611,1.611,0,0,1-2.226.078,1.966,1.966,0,0,0-1.346-.544A1.994,1.994,0,0,0,256,217.548v3.209h2.459a1.566,1.566,0,1,1,.027,3.131H256v6.574a1.592,1.592,0,0,1-3.183,0V217.548a5.146,5.146,0,0,1,8.644-3.778A1.612,1.612,0,0,1,261.54,215.995Z"
                transform="translate(-224.709 -212.398)"
                fill="#6d9dab"
              />
              <path
                d="M286.794,230.923v9.912a1.482,1.482,0,0,1-1.485,1.475h-.016a1.374,1.374,0,0,1-1.372-1.475v-.311a5.9,5.9,0,0,1-3.96,1.527,6.25,6.25,0,0,1,0-12.5h0a6.146,6.146,0,0,1,3.96,1.5v-.129a1.374,1.374,0,0,1,1.372-1.475,1.482,1.482,0,0,1,1.5,1.459Zm-3.6,4.893a3.248,3.248,0,1,0-3.235,3.209A3.248,3.248,0,0,0,283.2,235.816Z"
                transform="translate(-237.23 -222.615)"
                fill="#6d9dab"
              />
              <path
                d="M329.822,237.745v.155a5.05,5.05,0,0,1-5.124,4.736,5.308,5.308,0,0,1-3.572-1.4,5.258,5.258,0,0,1-3.572,1.4,5.024,5.024,0,0,1-5.124-4.893v-6.495a1.553,1.553,0,1,1,3.106-.027c0,.009,0,.018,0,.027v6.5a2.034,2.034,0,0,0,4.038,0v-6.5a1.553,1.553,0,0,1,3.106,0v6.5a2.034,2.034,0,0,0,4.038,0V231.2a1.553,1.553,0,0,1,3.106,0Z"
                transform="translate(-260.433 -222.735)"
                fill="#6d9dab"
              />
              <path
                d="M373.142,230.923v9.912a1.482,1.482,0,0,1-1.485,1.475h-.016a1.374,1.374,0,0,1-1.372-1.475v-.311a5.905,5.905,0,0,1-3.96,1.527,6.25,6.25,0,0,1,0-12.5,6.146,6.146,0,0,1,3.96,1.5v-.129a1.374,1.374,0,0,1,1.372-1.475,1.482,1.482,0,0,1,1.5,1.459C373.142,230.912,373.142,230.917,373.142,230.923Zm-3.6,4.893a3.248,3.248,0,1,0-3.235,3.209A3.248,3.248,0,0,0,369.544,235.816Z"
                transform="translate(-288.977 -222.615)"
                fill="#6d9dab"
              />
              <path
                d="M409.442,231.975a1.693,1.693,0,0,1-1.164.492,1.529,1.529,0,0,1-1.062-.415l-5.28-4.865v3.856a1.579,1.579,0,1,1-3.158,0V214.558a1.579,1.579,0,1,1,3.158,0v8.748l5.1-3.52a1.587,1.587,0,1,1,1.838,2.588l-4.322,2.924,4.814,4.425a1.617,1.617,0,0,1,.079,2.251Z"
                transform="translate(-312.18 -212.745)"
                fill="#6d9dab"
              />
            </g>
            <g transform="translate(92.432)">
              <g transform="translate(13.908 12.001)">
                <g>
                  <path
                    d="M298.23,97.4a2.122,2.122,0,1,1-2.121-2.122A2.122,2.122,0,0,1,298.23,97.4Z"
                    transform="translate(-293.988 -95.28)"
                    fill="#f7d8d0"
                  />
                </g>
                <g transform="translate(20.347 0.007)">
                  <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M348.993,97.412a2.115,2.115,0,1,1-2.115-2.115h0a2.115,2.115,0,0,1,2.115,2.115Z"
                    transform="translate(-344.762 -95.297)"
                    fill="#f7d8d0"
                  />
                </g>
              </g>
              <path
                d="M318.524,73.081a.979.979,0,0,1,.979.979V92.083a.979.979,0,0,1-.979.979H300.146a.979.979,0,0,1-.979-.977h0V74.058a.979.979,0,0,1,.979-.979"
                transform="translate(-283.184 -69.976)"
                fill="#f7d8d0"
              />
              <path
                d="M307.242,65.332c-12.884,0-11.935,11.172-11.64,12.635a10.964,10.964,0,0,0,6.445-3.563,10.512,10.512,0,0,1-2.357,3s7.594-.965,9.968-5.536c0,0,3.934,4.572,7.806,5.448l1.225.4s1.579-12.379-11.448-12.379"
                transform="translate(-280.963 -65.332)"
              />
              <g transform="translate(1.757 8.689)">
                <path
                  d="M283.077,91.035l-17.217-3.97a1.793,1.793,0,0,0-2.2,1.747v24.405a1.793,1.793,0,0,0,1.384,1.746l17.709,4.152h.32c2.768,0,5.033-1.777,5.033-3.948V94.982C288.111,92.81,285.946,91.743,283.077,91.035Z"
                  transform="translate(-263.665 -87.016)"
                  fill="#6d9dab"
                />
                <path
                  d="M329.7,91.035l17.217-3.97a1.792,1.792,0,0,1,2.2,1.747v24.4a1.793,1.793,0,0,1-1.384,1.746l-17.71,4.152h-.32c-2.768,0-5.033-1.777-5.033-3.948V94.982C324.67,92.811,326.834,91.743,329.7,91.035Z"
                  transform="translate(-300.224 -87.016)"
                  fill="#537d89"
                />
              </g>
              <path
                d="M259.28,111.569a2,2,0,1,1,3.917,0v2.312a2,2,0,1,1-3.917,0"
                transform="translate(-259.28 -91.596)"
                fill="#f7d8d0"
              />
              <g transform="translate(48.69 17.562)">
                <path
                  d="M384.7,113.881a2,2,0,1,1-3.919,0v-2.312a2,2,0,1,1,3.919,0Z"
                  transform="translate(-380.784 -109.157)"
                  fill="#f7d8d0"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

LogoIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default LogoIcon
