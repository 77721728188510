import api from '../../api'

export const fetchProfilesApi = () =>
  api({
    method: 'GET',
    url: '/Profiles',
  })

export const fetchDiagnosesApi = () =>
  api({
    method: 'GET',
    url: 'Profiles/diagnoses',
  })

export const createProfileApi = ({
  birthDate,
  diagnoses,
  firstName,
  lastName,
  image,
}) =>
  api({
    method: 'POST',
    url: '/Profiles',
    data: {
      birthDate,
      diagnoses,
      firstName,
      lastName,
      image,
      otherDiagnosis: '',
    },
  })

export const editProfileApi = ({
  birthDate,
  diagnoses,
  firstName,
  lastName,
  image,
  id,
}) =>
  api({
    method: 'PUT',
    url: `/Profiles/${id}`,
    data: {
      firstName,
      lastName,
      birthDate,
      diagnoses,
      image,
      otherDiagnosis: '',
    },
  })

export const removeProfileByIdApi = (id) =>
  api({
    method: 'DELETE',
    url: `/Profiles/${id}`,
  })

export const getProfileStatsApi = (profileId) =>
  api({
    method: 'GET',
    url: `/Statistics/${profileId}`,
  })
