import api from '../../api'

export const fetchTokenApi = ({ email, password }) =>
  api({
    method: 'POST',
    url: '/Token/CreateToken',
    data: { username: email, password },
    isSecured: false,
  })

export const registerApi = ({ email, password }) =>
  api({
    method: 'POST',
    url: '/Account/register',
    data: { email, password, confirmPassword: password },
    isSecured: false,
  })

export const sendCofrimationEmailApi = ({ email }) =>
  api({
    method: 'GET',
    url: `/Account/ConfirmUser/${email}`,
    isSecured: false,
  })

export const fetchTokenBySocialLoginApi = ({ tokenId }) =>
  api({
    method: 'POST',
    url: '/Account/ExternalLogin',
    data: { tokenId },
    isSecured: false,
  })

export const refreshTokenApi = ({ accessToken, refreshToken }) =>
  api({
    method: 'POST',
    url: '/Token/Refresh',
    data: { accessToken, refreshToken },
    isSecured: false,
  })

export const forgotPasswordApi = ({ email }) =>
  api({
    method: 'POST',
    url: '/Account/ForgotPassword',
    data: { email },
    isSecured: false,
  })

export const resetPasswordApi = ({ email, password, code }) =>
  api({
    method: 'POST',
    url: '/Account/ResetPassword',
    data: { email, password, code },
    isSecured: false,
  })

export const changePasswordApi = ({ oldPassword, newPassword }) =>
  api({
    method: 'POST',
    url: '/Account/ChangePassword',
    data: { oldPassword, newPassword },
  })
