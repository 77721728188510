import { createContext, useRef, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useProfile } from '../../profile/containers/ProfileProvider'
import { setScoreApi } from '../api'

const GameContext = createContext()

export default function GameProvider({ children }) {
  //-----------------
  // -- hooks
  //-----------------
  const history = useHistory()
  const location = useLocation()
  const gameId = parseInt(location.pathname.match(/[1-9]+/i)[0], 10)
  const gameAudioRef = useRef(new Audio())
  const gameVideoRef = useRef(null)
  const audioListenerRef = useRef(null)
  const { currentProfile } = useProfile()

  //-----------------
  // -- Methods
  //-----------------
  const setScore = useCallback(
    (score) => {
      setScoreApi({ gameId, profilId: currentProfile.id, score })
    },
    [currentProfile.id, gameId],
  )

  const onExit = () => history.push(location.state?.pathname || '/app')

  const playNewAudio = (audioSrc) => {
    return new Promise((resolve, reject) => {
      // if an other audio is called it ensures that the previous ones are cancelled
      if (audioListenerRef.current !== null) {
        gameAudioRef.current?.removeEventListener(
          'ended',
          audioListenerRef.current,
        )
      }
      if (gameAudioRef.current !== null) {
        audioListenerRef.current = resolve
        gameAudioRef.current.src = audioSrc
        gameAudioRef.current.play()
        // mute video when audio is played
        if (gameVideoRef.current) {
          gameVideoRef.current.muted = true
        }
        gameAudioRef.current.addEventListener(
          'ended',
          audioListenerRef.current,
          {
            once: true,
          },
        )
      }
    })
  }

  const playNewVideo = (videoSrc) => {
    if (gameVideoRef.current !== null) {
      if (!gameAudioRef.current || gameAudioRef.current?.paused) {
        gameVideoRef.current.muted = false
      }

      gameVideoRef.current.src = videoSrc
      gameVideoRef.current.play()
    }
  }

  // it ensurses that the video is called before the audio (the video will be muted by audio)
  const playNewVideoWithAudio = ({ audioSrc, videoSrc }) => {
    playNewVideo(videoSrc)
    playNewAudio(audioSrc)
  }

  const contextValues = {
    setScore,
    onExit,
    gameAudioRef,
    gameVideoRef,
    playNewAudio,
    playNewVideo,
    playNewVideoWithAudio,
  }

  return (
    <GameContext.Provider value={contextValues}>
      {children}
    </GameContext.Provider>
  )
}

GameProvider.propTypes = {
  children: PropTypes.node,
}

export function useGame() {
  const context = useContext(GameContext)
  if (context === undefined) {
    throw new Error(`useGame must be used within a GameProvider `)
  }
  return context
}
