import {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react'
import FullLoading from '../../../components/FullLoading'
import { useAuth } from '../../security/containers/AuthProvider'
import { fetchMeApi } from '../api'
import { PRIVILEGES, GUEST_STATUS } from '../definitions'

const UserContext = createContext()

const defaultState = {
  privileges: [],
  email: null,
  isSync: false,
  subDays: 0,
}

export default function UserProvider(props) {
  //-----------------
  // -- states
  //-----------------

  const [state, setState] = useState(defaultState)

  //-----------------
  // -- hooks
  //-----------------

  const { isAuthenticated, isInternalLogin } = useAuth()

  //-----------------
  // -- Methods
  //-----------------

  const hasUserPrivileges = useCallback(
    (privileges = []) => {
      return privileges.every((item) => state.privileges.includes(item))
    },
    [state.privileges],
  )

  //-----------------
  // -- effect
  //-----------------

  const syncAuthenticatedUser = useCallback(async () => {
    try {
      const { data } = await fetchMeApi()

      const formatedRoles = data.roles.filter(
        (item) => !['guest'].includes(item),
      )

      // check interenal-login privilege
      const privileges = [...formatedRoles]

      if (isInternalLogin) {
        privileges.push(PRIVILEGES.internalLogin)
      }

      if (data.isSubscribed === true) {
        privileges.push(PRIVILEGES.subscribed)
      } else if (data.guestStat === GUEST_STATUS.available) {
        privileges.push(PRIVILEGES.noSubscription)
      } else if (data.guestStat === GUEST_STATUS.active) {
        privileges.push(PRIVILEGES.guestSubscription)
      } else {
        privileges.push(PRIVILEGES.expiredSubscription)
      }

      setState((prevState) => ({
        ...prevState,
        email: data.email,
        privileges,
        isSync: true,
        subDays: data.subDays,
      }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, isSync: true }))
    }
  }, [isInternalLogin])

  // init user
  useEffect(() => {
    if (isAuthenticated) {
      syncAuthenticatedUser()
    } else {
      setState((prevState) => ({ ...prevState, privileges: [], email: null }))
    }
  }, [isAuthenticated, syncAuthenticatedUser])

  const isSubscribed = useMemo(
    () => hasUserPrivileges([PRIVILEGES.subscribed]),
    [hasUserPrivileges],
  )

  const contextValues = {
    hasUserPrivileges,
    syncAuthenticatedUser,
    subDays: state.subDays,
    isSubscribed,
  }

  if (!state.isSync && isAuthenticated) {
    return <FullLoading />
  }

  return <UserContext.Provider value={contextValues} {...props} />
}

export function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export function withUserPrivileges(WrappedComponent, privileges = []) {
  const HocComponent = (props) => {
    const { hasUserPrivileges } = useUser()

    if (!hasUserPrivileges(privileges)) {
      return null
    }

    return <WrappedComponent {...props} />
  }
  return HocComponent()
}
