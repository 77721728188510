import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchTargetsApi } from '../api'
import { createTree } from '../helpers/tree'

const StructureContext = createContext()

export default function StructureProvider(props) {
  //-----------------
  // -- states
  //-----------------

  const [state, setState] = useState({
    nav: null,
    isLoading: true,
    breadcrumb: [],
  })

  const [flatStrcuture, setFlatStrcuture] = useState([])
  //-----------------
  // -- hooks
  //-----------------

  const location = useLocation()
  const history = useHistory()

  //-----------------
  // -- Methods
  //-----------------
  const goTo = (item = {}) => {
    if (item.type === 1) {
      history.push({
        pathname: `/app/games/${item.content}`,
        state: { pathname: location.pathname + location.search },
      })
      return
    }

    if (!item.id) {
      history.push(`/app`)
    } else {
      const params = new URLSearchParams(location.search)
      const idsParams = params.get('ids') || null
      const ids = idsParams ? idsParams.split(',') : []
      const formatedIds = ids.length ? [...ids, item.id].join(',') : item.id
      history.push(`/app/?ids=${formatedIds}`)
    }
  }

  const getMenuByIds = useCallback((params) => {
    const { ids, data, counter = 0 } = params

    const found = { breadcrumb: [], nav: null }

    if (!ids.length) {
      const newDate = { ...data }

      found.nav = newDate
      found.breadcrumb = [
        {
          id: newDate.id,
          label: newDate.label,
        },
      ]
      return found
    }

    for (let i = 0; i < data.children.length; i += 1) {
      const item = data.children[i]

      if (item.id === Number(ids[counter])) {
        if (ids.length === counter + 1) {
          found.nav = { ...item }

          found.breadcrumb.push({
            id: data.id,
            label: data.label,
          })
          found.breadcrumb.push({
            id: item.id,
            label: item.label,
          })
          break
        }
        if (counter + 1 > ids.length) {
          break
        }

        const exist = getMenuByIds({
          ids,
          counter: counter + 1,
          data: item,
        })

        if (exist && exist.nav) {
          found.nav = exist.nav
          found.breadcrumb = [
            { id: data.id, label: data.label },
            ...exist.breadcrumb,
          ]

          break
        }
      }
    }

    return found
  }, [])

  const getFlatStructure = useCallback(async () => {
    let data = flatStrcuture

    if (!data.length) {
      const response = await fetchTargetsApi()
      data = [{ id: 0, label: 'القائمة الرئيسية' }, ...response.data]
      setFlatStrcuture(data)
    }
    return data
  }, [flatStrcuture])

  const getFormatedStructure = useCallback(async () => {
    const data = await getFlatStructure()
    const tree = createTree(data)
    return tree
  }, [getFlatStructure])

  const findMenu = useCallback(async () => {
    try {
      const params = new URLSearchParams(location.search)
      const idsParams = params.get('ids') || null

      const ids = idsParams ? idsParams.split(',') : []

      const data = await getFormatedStructure()

      const menu = getMenuByIds({ ids, data })

      const breadcrumb = menu.breadcrumb.map((item, index) => {
        const idsPath = []
        for (let i = 0; i <= index; i += 1) {
          const elm = menu.breadcrumb[i]
          if (elm.id) {
            idsPath.push(elm.id)
          }
        }
        const href = idsPath.length ? `/app/?ids=${idsPath.join(',')}` : '/app'
        return { ...item, href }
      })

      menu.breadcrumb = breadcrumb

      setState((prevState) => ({ ...prevState, ...menu, isLoading: false }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }))
    }
  }, [getFormatedStructure, getMenuByIds, location.search])

  //-----------------
  // -- context
  //-----------------
  const contextValues = {
    nav: state.nav,
    breadcrumb: state.breadcrumb,
    goTo,
    flatStrcuture,
    findMenu,
    isLoading: state.isLoading,
    getFlatStructure,
  }

  return <StructureContext.Provider value={contextValues} {...props} />
}

export function useStructure() {
  const context = useContext(StructureContext)
  if (context === undefined) {
    throw new Error(`useStructure must be used within a StructureProvider`)
  }
  return context
}
