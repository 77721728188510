import { format } from 'date-fns'
import {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import FullLoading from '../../../components/FullLoading'
import { useAlert } from '../../alert/containers/AlertProvider'
import { getPresignedUrlsApi, uploadAwsFile } from '../../cdn/api'
import { useAuth } from '../../security/containers/AuthProvider'
import { useUser } from '../../user/containers/UserProvider'
import { PRIVILEGES } from '../../user/definitions'
import {
  fetchProfilesApi,
  createProfileApi,
  editProfileApi,
  removeProfileByIdApi,
  fetchDiagnosesApi,
  getProfileStatsApi,
} from '../api'

const ProfileContext = createContext()

export const CURRENT_PROFILE_STORAGE_KEY = 'current-profile'

export default function ProfileProvider(props) {
  //-----------------
  // -- states
  //-----------------

  const [state, setState] = useState({
    isSync: false,
    profiles: [],
    isLoading: false,
    currentProfile: null,
    diagnoses: [],
    maxDate: format(new Date(), 'yyyy-MM-dd'),
  })

  //-----------------
  // -- hooks
  //-----------------

  const { isAuthenticated } = useAuth()
  const { hasUserPrivileges } = useUser()
  const { showAlert } = useAlert()
  const history = useHistory()
  //-----------------
  // -- Methods
  //-----------------

  const getAndSyncCurrentProfileByProfiles = (profiles = []) => {
    const storageProfiles = localStorage.getItem(CURRENT_PROFILE_STORAGE_KEY)
    const parsedProfiles = JSON.parse(storageProfiles)

    let currentProfile = null

    if (profiles.length && parsedProfiles) {
      currentProfile =
        profiles.find((item) => item.id === parsedProfiles.id) || profiles[0]
    }

    if (profiles.length && !parsedProfiles) {
      const [selectedItem] = profiles
      currentProfile = selectedItem
    }

    if (currentProfile) {
      localStorage.setItem(
        CURRENT_PROFILE_STORAGE_KEY,
        JSON.stringify(currentProfile),
      )
      return currentProfile
    }

    localStorage.removeItem(CURRENT_PROFILE_STORAGE_KEY)
    return currentProfile
  }

  const selectProfile = useCallback(
    ({ id }) => {
      const currentProfile = state.profiles.find((item) => item.id === id)

      localStorage.setItem(
        CURRENT_PROFILE_STORAGE_KEY,
        JSON.stringify(currentProfile),
      )

      setState((prevState) => ({ ...prevState, currentProfile }))
    },
    [state.profiles],
  )

  const createProfile = useCallback(
    async (params) => {
      const {
        dateOfBirth = null,
        diagnostic = [],
        lastName = '',
        firstName = '',
        picture,
      } = params

      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }))
        const birthDate = dateOfBirth
          ? new Date(dateOfBirth).toISOString()
          : null

        const diagnoses = diagnostic.map((item) => item.id)

        let image = picture

        if (image) {
          image = await fetch(image).then((response) => response.blob())

          const { data } = await getPresignedUrlsApi({
            fileTypes: [image.type],
          })

          const { fileName, presignedUrl } = data

          await uploadAwsFile({ body: image, presignedUrl })

          image = fileName
        }

        const displayName = `${firstName} ${lastName}`

        const imageLink = image
          ? `${process.env.REACT_APP_IMAGE_CDN_URL}/${image}`
          : null

        const item = {
          birthDate,
          diagnoses,
          firstName,
          lastName,
          imageLink,
          displayName,
        }

        const { data: id } = await createProfileApi({ ...item, image })

        item.id = id

        const profiles = [...state.profiles, item]

        const currentProfile = profiles.find((elem) => elem.id === id)

        localStorage.setItem(
          CURRENT_PROFILE_STORAGE_KEY,
          JSON.stringify(currentProfile),
        )

        setState((prevState) => ({
          ...prevState,
          profiles,
          currentProfile,
          isLoading: false,
        }))

        history.push('/app/profiles')

        showAlert({ message: 'تم إضافة المشترك بنجاح', variant: 'success' })
      } catch (error) {
        // handle error
        showAlert({ message: 'هناك خطأ ما' })
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }))
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.profiles, showAlert],
  )

  const editProfile = useCallback(
    async (params) => {
      const {
        id,
        dateOfBirth = null,
        diagnostic = [],
        lastName = '',
        firstName = '',
        picture = null,
      } = params

      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }))

        const birthDate = dateOfBirth
          ? new Date(dateOfBirth).toISOString()
          : null

        const diagnoses = diagnostic.map((item) => item.id)

        const isNewImage = Boolean(
          picture &&
            !picture.split(`${process.env.REACT_APP_IMAGE_CDN_URL}/`)[1],
        )

        let image

        if (picture && isNewImage) {
          const file = await fetch(picture).then((response) => response.blob())

          const { data } = await getPresignedUrlsApi({
            fileTypes: [file.type],
          })

          await uploadAwsFile({
            body: file,
            presignedUrl: data.presignedUrl,
          })

          image = data.fileName
        }

        const displayName = `${firstName} ${lastName}`

        const imageLink = image
          ? `${process.env.REACT_APP_IMAGE_CDN_URL}/${image}`
          : picture

        const item = {
          id,
          birthDate,
          diagnoses,
          firstName,
          lastName,
          imageLink,
          displayName,
        }

        await editProfileApi({ ...item, image })

        const profiles = state.profiles.map((elem) => {
          if (elem.id !== item.id) {
            return elem
          }
          return item
        })

        let { currentProfile } = state

        if (currentProfile.id === item.id) {
          currentProfile = item

          localStorage.setItem(
            CURRENT_PROFILE_STORAGE_KEY,
            JSON.stringify(currentProfile),
          )
        }

        setState((prevState) => ({
          ...prevState,
          profiles,
          currentProfile,
          isLoading: false,
        }))

        showAlert({ message: 'تم تعديل المشترك بنجاح', variant: 'success' })

        history.push('/app/profiles')
      } catch (error) {
        // handle error
        showAlert({ message: 'هناك خطأ ما' })
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }))
      }
    },
    [history, showAlert, state],
  )

  const removeProfileById = useCallback(
    async (id) => {
      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }))

        await removeProfileByIdApi(id)

        const profiles = state.profiles.filter((item) => item.id !== id)

        let { currentProfile } = state

        if (currentProfile && currentProfile.id === id) {
          currentProfile = getAndSyncCurrentProfileByProfiles(profiles)
        }

        setState((prevState) => ({
          ...prevState,
          profiles,
          currentProfile,
          isLoading: false,
        }))

        showAlert({
          message: 'تم حذف المستخدم بنجاح',
          variant: 'success',
        })

        return true
      } catch (error) {
        // handle error
        showAlert({ message: 'هناك خطأ ما' })
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }))
        return false
      }
    },
    [state, showAlert],
  )

  //-----------------
  // -- effect
  //-----------------

  const syncProfiles = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const { data } = await fetchProfilesApi()
        const { data: diagnosesServer } = await fetchDiagnosesApi()

        const diagnoses = diagnosesServer.filter(
          (item) => item.label !== 'Other',
        )

        const fromatedData = data.map((item) => ({
          ...item,
          displayName: `${item.firstName} ${item.lastName}`,
        }))

        const filtredData = fromatedData.filter((item) => item.name !== 'Guest')

        const profiles = filtredData

        if (
          !filtredData.length &&
          hasUserPrivileges([PRIVILEGES.guestSubscription])
        ) {
          profiles.push({ id: 0, displayName: 'Guest', imageLink: null })
        }

        const currentProfile = getAndSyncCurrentProfileByProfiles(profiles)

        setState((prevState) => ({
          ...prevState,
          profiles,
          isSync: true,
          currentProfile,
          diagnoses,
        }))
      } catch (error) {
        // handle error
        showAlert({ message: 'هناك خطأ ما' })
        setState((prevState) => ({ ...prevState, isSync: true }))
      }
    }
  }, [hasUserPrivileges, isAuthenticated, showAlert])

  const getCurrentProfileStats = useCallback(async () => {
    if (state.currentProfile && state.currentProfile.id) {
      const { data } = await getProfileStatsApi(state.currentProfile.id)
      return data
    }
    return []
  }, [state.currentProfile])

  // init user
  useEffect(() => {
    if (!hasUserPrivileges([PRIVILEGES.admin])) {
      syncProfiles()
    }
  }, [hasUserPrivileges, syncProfiles])

  // context
  const contextValues = {
    createProfile,
    removeProfileById,
    isLoading: state.isLoading,
    currentProfile: state.currentProfile,
    selectProfile,
    profiles: state.profiles,
    diagnoses: state.diagnoses,
    maxDate: state.maxDate,
    getCurrentProfileStats,
    editProfile,
  }

  // renders

  if (
    !state.isSync &&
    isAuthenticated &&
    !hasUserPrivileges([PRIVILEGES.admin])
  ) {
    return <FullLoading />
  }

  return <ProfileContext.Provider {...props} value={contextValues} />
}

export function useProfile() {
  const context = useContext(ProfileContext)
  if (context === undefined) {
    throw new Error(`useProfile must be used within a ProfileProvider`)
  }
  return context
}
