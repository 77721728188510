import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
  direction: 'rtl',
  shape: {
    borderRadius: '2px 5px 7px 5px/ 36px 25px 6px 15px',
    borderRadiusRound: '185px 200px 160px 185px/ 160px 195px 195px 190px',
  },
  palette: {
    primary: {
      light: '#9dcedd',
      main: '#6d9dab',
      dark: '#3e6f7c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6c6fbf',
      main: '#3B448E',
      dark: '#001d60',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Tajawal', 'Cairo', 'sans-serif'].join(','),
    fontSize: 18,

    body1: {
      fontFamily: "'Tajawal', sans-serif",
    },
    body2: {
      fontFamily: "'Tajawal', sans-serif",
    },
    button: {
      fontFamily: "'Tajawal', sans-serif",
    },
    caption: {
      fontFamily: "'Tajawal', sans-serif",
    },
    overline: {
      fontFamily: "'Tajawal', sans-serif",
      lineHeight: 1.66,
    },

    h1: {
      fontFamily: "'Cairo', sans-serif",
    },

    h2: {
      fontFamily: "'Cairo', sans-serif",
    },
    h3: {
      fontFamily: "'Cairo', sans-serif",
    },
    h4: {
      fontFamily: "'Cairo', sans-serif",
    },
    h5: {
      fontFamily: "'Cairo', sans-serif",
    },
    h6: {
      fontFamily: "'Cairo', sans-serif",
    },
    subtitle1: {
      fontFamily: "'Cairo', sans-serif",
    },
    subtitle2: {
      fontFamily: "'Cairo', sans-serif",
    },
  },

  overrides: {
    MuiPickersToolbar: {
      toolbar: {},
    },
    MuiPickersCalendarHeader: {
      switchHeader: {},
      dayLabel: {
        width: '100px',
        padding: 0,
        margin: '0 2px',
      },
    },
    MuiPickersDay: {
      day: {
        margin: '5px',
      },
      daySelected: {},
      dayDisabled: {},
      current: {},
    },
    MuiPickersModal: {
      dialogAction: {},
    },
  },
})
