import { createContext, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Snackbar } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import RTL from '../../../components/RTL'

const AlertContext = createContext()

const initialState = {
  message: '',
  variant: 'error',
  isOpen: false,
  autoHideDuration: 10000,
}

export default function AlertProvider(props) {
  //-----------------
  // -- props
  //-----------------
  const { children } = props

  //-----------------
  // -- states
  //-----------------
  const [state, setState] = useState(initialState)

  //-----------------
  // -- hooks
  //-----------------

  //-----------------
  // -- Methods
  //-----------------
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setState((prevState) => ({ ...prevState, isOpen: false }))
  }

  const showAlert = useCallback(
    ({
      message = '',
      variant = initialState.variant,
      autoHideDuration = initialState.autoHideDuration,
    }) => {
      setState((prevState) => ({
        ...prevState,
        message,
        variant,
        isOpen: true,
        autoHideDuration,
      }))
    },
    [],
  )

  const actionButton = (
    <>
      <Button
        variant="outlined"
        color="inherit"
        style={{ color: 'white' }}
        size="large"
        onClick={handleClose}
      >
        حسنا
      </Button>
    </>
  )

  //-----------------
  // -- effect
  //-----------------

  const contextValues = { showAlert }

  return (
    <AlertContext.Provider value={contextValues}>
      <RTL>
        <Snackbar
          open={state.isOpen}
          autoHideDuration={state.autoHideDuration}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ marginBottom: '10%' }}
        >
          <Alert
            action={actionButton}
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={state.variant}
            style={{ padding: '30px' }}
          >
            <AlertTitle>تنبيه</AlertTitle>

            {state.message}
          </Alert>
        </Snackbar>
      </RTL>
      {children}
    </AlertContext.Provider>
  )
}

AlertProvider.propTypes = {
  children: PropTypes.node,
}

export function useAlert() {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error(`useAlert must be used within a AlertProvider`)
  }
  return context
}
