import { renderRoutes } from 'react-router-config'
import { Redirect } from 'react-router-dom'
import { findRouteByPathname } from '../../../routes/helper'
import { useProfile } from '../../profile/containers/ProfileProvider'
import { useSubscription } from '../../subscription/containers/SubscriptionProvider'
import { useUser } from '../../user/containers/UserProvider'
import { PRIVILEGES } from '../../user/definitions'
import { useAuth } from '../containers/AuthProvider'

function Firewall(props) {
  const {
    route: { routes },
    location: { pathname },
  } = props

  const { isAuthenticated } = useAuth()
  const { hasUserPrivileges } = useUser()
  const { profiles } = useProfile()
  const { hasRequestedSubscriptions } = useSubscription()

  // all routes are secured by default
  const { isSecured, privileges } = findRouteByPathname({
    routes,
    pathname,
  })

  const isRootPath = pathname === '/'
  const isAdminPath = /^\/admin(\/.*)?$/.test(pathname)
  const isPricingPath = /^\/app\/pricing(\/.*)?$/.test(pathname)
  const isSubscriptionPath = /^\/app\/subscriptions(\/)?$/.test(pathname)
  const isAddProfilePath = /^\/app\/profiles\/add(\/)?$/.test(pathname)
  const isInvoicePath = /^\/app\/cib-transaction-detail(\/.*)?$/.test(pathname)

  const isAdmin = hasUserPrivileges([PRIVILEGES.admin])

  if (isAuthenticated && (isRootPath || !isSecured)) {
    return <Redirect to={isAdmin ? '/admin' : '/app'} />
  }

  if (!isAuthenticated && (isRootPath || isSecured)) {
    return <Redirect to="/login" />
  }

  if (
    isAuthenticated &&
    !isAdmin &&
    !isPricingPath &&
    !hasRequestedSubscriptions &&
    !isInvoicePath &&
    (hasUserPrivileges([PRIVILEGES.noSubscription]) ||
      hasUserPrivileges([PRIVILEGES.expiredSubscription]))
  ) {
    return <Redirect to="/app/pricing" />
  }

  if (
    isAuthenticated &&
    !isSubscriptionPath &&
    !isAdmin &&
    hasRequestedSubscriptions &&
    !isInvoicePath &&
    (hasUserPrivileges([PRIVILEGES.noSubscription]) ||
      hasUserPrivileges([PRIVILEGES.expiredSubscription]))
  ) {
    return <Redirect to="/app/subscriptions" />
  }

  if (
    isAuthenticated &&
    !isAdmin &&
    hasUserPrivileges([PRIVILEGES.subscribed]) &&
    !profiles.length &&
    !isAddProfilePath &&
    !isInvoicePath
  ) {
    return <Redirect to="/app/profiles/add" />
  }

  if (isAdmin && !isAdminPath) {
    return <Redirect to="/app/admin" />
  }

  if (!hasUserPrivileges(privileges)) {
    return <Redirect to="/" />
  }

  return renderRoutes(routes)
}

export default Firewall
