import globalApi from '../../api'
import { refreshTokenApi } from './api'

const AUTH_STORAGE_KEY = 'auth'

export const getstoredAuthInfo = () => {
  const storedData = localStorage.getItem(AUTH_STORAGE_KEY)
  if (storedData) {
    return JSON.parse(storedData)
  }
  return null
}

export const storeAuthInfo = (data) => {
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(data))
}

const refreshToken = async () => {
  const storedInfo = getstoredAuthInfo()
  const { data } = await refreshTokenApi(storedInfo)
  storeAuthInfo({ ...storedInfo, ...data })
}

const interceptRequestSuccess = ({ config }) => {
  const storedInfo = getstoredAuthInfo()
  // Do nothing if the isSecure property of the config request is set to false
  if (config.isSecured === false || !storedInfo) {
    return config
  }
  const { accessToken } = storedInfo
  // Create the new request config
  const newConfig = { ...config }
  newConfig.headers.Authorization = `Bearer ${accessToken}`

  return newConfig
}

const interceptReponseFailure = async ({ error, logout }) => {
  const { response, config } = error
  const { status } = response
  const { isChecked403 = false } = config

  // check if 401 is from  our app api
  if ((status === 401 || status === 403) && !isChecked403) {
    try {
      await refreshToken()
      if (status === 403) {
        config.isChecked403 = true
      }
      return globalApi.request(config)
    } catch (err) {
      logout(true)
      return Promise.reject(new Error('sessionExpired'))
    }
  } else {
    return Promise.reject(error)
  }
}

export default async function interceptor({ logout }) {
  // Intercept the request
  const requestInterceptor = globalApi.interceptors.request.use(
    // Do something before the request is sent
    (config) => interceptRequestSuccess({ config }),
    // Do something with request error
    (error) => Promise.reject(error),
  )

  // Add a response interceptor
  const responseInterceptor = globalApi.interceptors.response.use(
    // Do something with response data
    (response) => response,
    // Do something with response error
    async (error) => interceptReponseFailure({ error, logout }),
  )

  // clear interceptor
  return function removeInterceptors() {
    globalApi.interceptors.request.eject(requestInterceptor)
    globalApi.interceptors.response.eject(responseInterceptor)
  }
}
