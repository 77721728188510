import React from 'react'
import { Avatar, Dialog, DialogContent, Badge } from '@material-ui/core'
import clsx from 'clsx'
import DoneIcon from '@material-ui/icons/Done'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'
import { useProfile } from '../../../modules/profile/containers/ProfileProvider'
import useStyles from './styles'
import { withUserPrivileges } from '../../../modules/user/containers/UserProvider'
import { PRIVILEGES } from '../../../modules/user/definitions'

const ProfileMenu = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const { currentProfile, profiles, selectProfile } = useProfile()

  const handleModalClose = () => setOpen(false)

  const selectHandler = (profile) => {
    selectProfile(profile)
    handleModalClose()
  }

  return (
    <>
      {currentProfile && (
        <Avatar
          className={classes.avatar}
          alt={currentProfile.displayName}
          src={currentProfile.imageLink}
          onClick={() => setOpen(true)}
        >
          {currentProfile.displayName[0]}
        </Avatar>
      )}

      <Dialog open={open} onClose={handleModalClose}>
        <DialogContent className={classes.dialogContent}>
          {profiles.map((profile, index) => (
            <button
              key={profile.id || index}
              onClick={() => selectHandler(profile)}
              type="button"
              className={classes.ProfileButton}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                overlap="circle"
                badgeContent={
                  profile.id === currentProfile.id && (
                    <div className={classes.badgeContent}>
                      <DoneIcon className={classes.badgeContentIcon} />
                    </div>
                  )
                }
              >
                <div
                  className={clsx(
                    classes.profileCircle,
                    profile.id === currentProfile.id &&
                      classes.activeProfileCircle,
                  )}
                >
                  <Avatar
                    className={clsx(classes.avatar, classes.avatarBig)}
                    alt={profile.displayName}
                    src={profile.imageLink}
                  >
                    {profile.displayName[0]}
                  </Avatar>
                </div>
              </Badge>
              <div>{profile.displayName}</div>
            </button>
          ))}
          {profiles.length < 3 &&
            withUserPrivileges(
              () => (
                <Link
                  className={classes.addButton}
                  onClick={handleModalClose}
                  to="/app/profiles/add"
                  type="button"
                >
                  <AddIcon />
                </Link>
              ),
              [PRIVILEGES.subscribed],
            )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ProfileMenu
