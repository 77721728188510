import { loadStripe } from '@stripe/stripe-js'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import FullLoading from '../../../components/FullLoading'
import { useAlert } from '../../alert/containers/AlertProvider'
import { useAuth } from '../../security/containers/AuthProvider'
import { useUser } from '../../user/containers/UserProvider'
import { PRIVILEGES } from '../../user/definitions'
import {
  activeGuestApi,
  fetchRequestedSubscriptionApi,
  cancelMyRequestedSubscriptionApi,
  fetchMySubscriptionApi,
  requestedSubscriptionApi,
  fetchRequestedSubscriptionByCodeApi,
  validateRequestedSubscriptionApi,
  cancelRequestedSubscriptionApi,
  createEPaymentSessionApi,
  fetchPacksApi,
} from '../api'

import flagImg from '../assets/flag.svg'
// import crownImg from '../assets/crown.svg'
// import infinitImg from '../assets/infinit.svg'

const SubscriptionContext = createContext()

const stripePromise = loadStripe(
  'pk_test_51I6aS3DdMSkuBVarJtXGrPc2BDwbFDXxauebfFeviNDw3Yu9lav5YNlJoxbNScSWRCLWzT8eTVlEb9sZKfXDpQct00ySyW8yQj',
)

const DEFAULT_STATE = {
  isSync: true,
  subscriptions: [],
  requests: [],
}

function formatPackage(pack) {
  switch (pack.id) {
    case 1:
      return {
        id: pack.id,
        title: 'مشترك',
        img: flagImg,
        days: 'كل شهر',
        price: `${pack.price} دج`,
      }
    default:
      throw new Error('package dosent exist')
  }
}

export default function SubscriptionProvider(props) {
  //-----------------
  // -- states
  //-----------------
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState(DEFAULT_STATE)
  const [packs, setPacks] = useState([])

  //-----------------
  // -- hooks
  //-----------------
  const { syncAuthenticatedUser, subDays, hasUserPrivileges } = useUser()
  const history = useHistory()
  const { showAlert } = useAlert()
  const { isAuthenticated } = useAuth()
  //-----------------
  // -- Methods
  //-----------------

  const subscribeToGuest = async () => {
    try {
      setIsLoading(true)
      await activeGuestApi()
      await syncAuthenticatedUser()
      history.push('/app')
      showAlert({ message: 'مرحبًا بك في المنصة', variant: 'success' })
    } catch (error) {
      showAlert({ message: 'هناك خطأ ما' })
    } finally {
      setIsLoading(false)
    }
  }

  const subscribeToEPayment = async (packageId) => {
    try {
      setIsLoading(true)
      const stripe = await stripePromise
      const { data } = await createEPaymentSessionApi({ packageId })

      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      })

      // console.log('result')
      // console.log(result)
      if (result.error) {
        // handle error
      }
    } catch (error) {
      // handleError
    } finally {
      setIsLoading(false)
    }
    // await syncAuthenticatedUser()
    // history.push('/app')
    // showAlert({ message: 'مرحبًا بك في المنصة', variant: 'success' })
  }

  const subscribeToBank = async (packageId) => {
    try {
      setIsLoading(true)
      await requestedSubscriptionApi(packageId)
      await syncSubscriptions()
      history.push('/app/subscriptions')
      showAlert({
        message: 'تم إنشاء رقم طلب الاشتراك بنجاح',
        variant: 'success',
      })
    } catch (error) {
      showAlert({ message: 'هناك خطأ ما' })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchSubscriptions = useCallback(
    async ({ pageSize = 5, page = 0 }) => {
      try {
        const skip = page * pageSize
        const { data, headers } = await fetchMySubscriptionApi({
          take: pageSize,
          skip,
        })
        return {
          data,
          total: Number(headers['x-total-count']),
        }
      } catch (error) {
        showAlert({ message: 'هناك خطأ ما' })
        return { data: [], total: 0 }
        // handle error
      }
    },
    [showAlert],
  )

  const subscribeByDays = async (days) => {
    try {
      setIsLoading(true)
      await requestedSubscriptionApi({ duration: days })
      await syncSubscriptions()
      history.push('/app/subscriptions')
      showAlert({ message: 'تم إرسال طلب الاشتراك بنجاح', variant: 'success' })
    } catch (error) {
      showAlert({ message: 'هناك خطأ ما' })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchRequestedSubscriptionByCode = useCallback(
    async (code) => {
      try {
        setIsLoading(true)
        const { data } = await fetchRequestedSubscriptionByCodeApi(code)
        return data
      } catch (error) {
        showAlert({ message: 'لا يوجد اشتراك بهذا الرمز', duration: 5000 })
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [showAlert],
  )

  const cancelMyRequestedSubscription = async ({ code, userId }) => {
    try {
      setIsLoading(true)
      await cancelMyRequestedSubscriptionApi({ code, userId })

      setState((prev) => ({
        ...prev,
        requests: prev.requests.filter((item) => item.code !== code),
      }))

      showAlert({ message: 'تم إلغاء طلب الاشتراك بنجاح', variant: 'success' })
      return true
    } catch (error) {
      showAlert({ message: 'هناك خطأ ما' })
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const syncSubscriptions = async () => {
    try {
      const { data } = await fetchRequestedSubscriptionApi()
      setState((pre) => ({
        ...pre,
        isSync: false,
        requests: data,
      }))
    } catch (error) {
      setState((pre) => ({ ...pre, isSync: false }))
    }
  }

  const validateSubscription = useCallback(
    async ({ code, userId }) => {
      try {
        setIsLoading(true)
        await validateRequestedSubscriptionApi({ code, userId })
        showAlert({
          message: 'تم قبول طلب الاشتراك بنجاح',
          variant: 'success',
        })
        return true
      } catch (error) {
        showAlert({ message: 'هناك خطأ ما' })
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [showAlert],
  )

  const getPacks = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await fetchPacksApi()
      const formatedData = response.data.map((data) => formatPackage(data))
      setPacks(formatedData)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const cancelSubscription = useCallback(
    async ({ code, userId }) => {
      try {
        setIsLoading(true)
        await cancelRequestedSubscriptionApi({ code, userId })
        showAlert({
          message: 'تم إلغاء طلب الاشتراك بنجاح',
          variant: 'success',
        })
        return true
      } catch (error) {
        showAlert({ message: 'هناك خطأ ما' })
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [showAlert],
  )

  //-----------------
  // -- effect
  //-----------------

  useEffect(() => {
    if (isAuthenticated && !hasUserPrivileges([PRIVILEGES.admin])) {
      syncSubscriptions()
    } else {
      setState(DEFAULT_STATE)
      setPacks([])
    }
  }, [hasUserPrivileges, isAuthenticated])

  const hasRequestedSubscriptions = Boolean(state.requests.length)
  const requestedSubscription = state.requests[0]

  const contextValues = {
    isLoading,
    hasRequestedSubscriptions,
    requestedSubscription,
    subDays,
    cancelMyRequestedSubscription,
    subscribeToGuest,
    subscribeByDays,
    fetchSubscriptions,
    fetchRequestedSubscriptionByCode,
    cancelSubscription,
    validateSubscription,
    subscribeToEPayment,
    getPacks,
    packs,
    subscribeToBank,
  }

  if (
    isAuthenticated &&
    state.isSync &&
    !hasUserPrivileges([PRIVILEGES.admin])
  ) {
    return <FullLoading />
  }
  return <SubscriptionContext.Provider value={contextValues} {...props} />
}

export function useSubscription() {
  const context = useContext(SubscriptionContext)
  if (context === undefined) {
    throw new Error(
      `useSubscription must be used within a SubscriptionProvider`,
    )
  }
  return context
}
