import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  avatar: {
    marginLeft: theme.spacing(),
    borderRadius: theme.shape.borderRadiusRound,
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  dialogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '600px',
  },
  avatarBig: {
    height: '100%',
    width: '100%',
  },

  profileCircle: {
    height: '120px',
    width: '120px',
    margin: `${theme.spacing(2)}px ${theme.spacing()}px`,
    cursor: 'pointer',
    background: 'transparent',
    borderRadius: theme.shape.borderRadiusRound,
    border: '3px solid transparent',
    padding: '0px',
    transition: `${theme.transitions.easing.easeIn} 150ms all`,
    '&:hover': {
      padding: theme.spacing() / 2,
      borderColor: theme.palette.secondary.main,
    },
  },
  activeProfileCircle: {
    padding: theme.spacing() / 2,
    borderColor: theme.palette.primary.main,
  },
  ProfileButton: {
    padding: '0px',
    background: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:focus': {
      outline: 'none',
    },
  },
  badgeContent: {
    borderRadius: theme.shape.borderRadiusRound,
    background: theme.palette.primary.light,
    transform: `translate(${theme.spacing()}px ,-${theme.spacing()}px)`,
  },
  badgeContentIcon: {
    fill: theme.palette.primary.contrastText,
  },
  addButton: {
    height: '120px',
    width: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadiusRound,
    background: theme.palette.grey[300],
    color: theme.palette.text.primary,
    border: 'none',
    cursor: 'pointer',
    margin: `${theme.spacing(2)}px ${theme.spacing()}px`,
    transition: `${theme.transitions.easing.easeIn} 150ms all`,
    '&:hover': {
      background: theme.palette.grey[400],
    },
    '&:focus': {
      outline: 'none',
    },
  },
}))
