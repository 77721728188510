export const PRIVILEGES = {
  internalLogin: 'internal-login',
  admin: 'administrator',
  subscribed: 'subscribed',
  noSubscription: 'no-subscription',
  guestSubscription: 'guest-subscription',
  expiredSubscription: 'expired-subscription',
}

export const GUEST_STATUS = {
  available: 0,
  active: 1,
  expired: 2,
}
