import loadable from '@loadable/component'
import { matchRoutes } from 'react-router-config'
import FullLoading from '../components/FullLoading'

function findRouteByPathname({ routes = [], pathname = '' }) {
  const matchedRoutes = matchRoutes(routes, pathname)
  const foundRoute = matchedRoutes.find((item) => item.match.isExact)

  if (foundRoute) {
    return foundRoute.route
  }

  return {}
}

export function getParentPathOfPathname(pathname) {
  if (!pathname) {
    return pathname
  }

  const path =
    pathname.substring(pathname.length - 1) === '/'
      ? pathname.substring(0, pathname.length - 1)
      : pathname

  return path.split('/').slice(0, -1).join('/')
}

function processRoutes({
  routes = [],
  path = '',
  notFoundComponent,
  isSecured = false,
  privileges = [],
}) {
  const formatedRoutes = routes.map((route) => {
    const item = { ...route }

    // formatPath
    if (item.path) {
      item.path = path + route.path
      item.isSecured = item.isSecured || isSecured
      item.privileges = Array.isArray(item.privileges)
        ? [...new Set([...privileges, ...item.privileges])]
        : privileges
    }
    item.exact = Boolean((!item.routes || !item.routes.length) && item.path)

    // item.exact = Boolean(item.routes && item.routes.length)
    if (item.routes && item.routes.length) {
      item.routes = processRoutes({
        routes: item.routes,
        path: item.path,
        notFoundComponent,
        isSecured: item.isSecured,
        isAdmin: item.isAdmin,
        privileges: item.privileges,
      })

      // add notFoundRoute
      if (notFoundComponent) {
        item.routes.push({
          path: '*',
          component: notFoundComponent,
        })
      }
    }

    if (item.load) {
      item.component = loadable(item.load, { fallback: <FullLoading /> })
    }

    return item
  })

  return formatedRoutes
}

export { processRoutes as default, findRouteByPathname }
