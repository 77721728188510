import api from '../../api'

export const fetchRequestedSubscriptionApi = () =>
  api({
    method: 'GET',
    url: '/Subscriptions/MyRequests',
  })

export const fetchMySubscriptionApi = ({ take = 5, skip = 0 }) =>
  api({
    method: 'GET',
    url: `/Subscriptions?take=${take}&skip=${skip}`,
  })

export const activeGuestApi = () =>
  api({
    method: 'GET',
    url: '/Subscriptions/ActivateGuest',
  })

export const cancelMyRequestedSubscriptionApi = ({ code, userId }) =>
  api({
    method: 'POST',
    url: '/Subscriptions/CancelMyRequest',
    data: { code, userId },
  })

export const requestedSubscriptionApi = (packageId) =>
  api({
    method: 'POST',
    url: '/Subscriptions/RequestSub',
    data: { packageId },
  })

export const fetchRequestedSubscriptionByCodeApi = (code) =>
  api({
    method: 'GET',
    url: `/Subscriptions/RequestByCode?requestCode=${code}`,
  })

export const validateRequestedSubscriptionApi = ({ code, userId }) =>
  api({
    method: 'POST',
    url: `/Subscriptions/ValidateRequest`,
    data: {
      code,
      userId,
    },
  })

export const cancelRequestedSubscriptionApi = ({ code, userId }) =>
  api({
    method: 'POST',
    url: `/Subscriptions/CancelRequest`,
    data: {
      code,
      userId,
    },
  })

export const createEPaymentSessionApi = ({ packageId }) =>
  api({
    method: 'POST',
    url: `/Epayment/create-checkout-session?packageId=${packageId}`,
  })

export const fetchPacksApi = () =>
  api({
    method: 'GET',
    url: `/Epayment/Packages`,
  })
