import {
  AppBar,
  Box,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'
import { Link } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings'
import { useState } from 'react'
import useStyles from './styles'
import LogoIcon from '../../icons/special/Logo'
import { useAuth } from '../../modules/security/containers/AuthProvider'

function AdminLayout(props) {
  const { route } = props
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const { logout } = useAuth()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AppBar
          color="transparent"
          classes={{ root: classes.appbar }}
          component={Box}
          boxShadow={2}
        >
          <Toolbar>
            <Link to="/admin">
              <LogoIcon width={130} height={50} />
            </Link>
            <div className={classes.actions}>
              <IconButton onClick={handleClick} color="inherit">
                <SettingsIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={handleClose}
            component={Link}
            to="/admin/change-password"
          >
            تغيير كلمة المرور
          </MenuItem>

          <Divider />
          <MenuItem
            onClick={() => {
              handleClose()
              return logout()
            }}
          >
            خروج
          </MenuItem>
        </Menu>
      </div>

      <div className={classes.appBarSpacer} />

      <Container className={classes.content} fixed>
        {renderRoutes(route.routes)}
      </Container>
    </div>
  )
}

AdminLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  route: PropTypes.object.isRequired,
}

export default AdminLayout
