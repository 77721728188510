import { makeStyles } from '@material-ui/core'
import schoolImg from './school-pattern.png'

export default makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundImage: `url(${schoolImg})`,
    overflow: 'hidden',
  },
  header: {
    flexGrow: 1,
  },
  actions: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'rgba(0,0,0, 0.85)',
    alignItems: 'center',
  },
  appbar: {
    border: '2px solid',
    borderRadius: '0 0 15px 255px / 0 0 255px 15px',
    backgroundColor: 'white',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    minHeight: '91vh',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  avatar: {
    marginLeft: theme.spacing(),
  },
}))
