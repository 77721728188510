import {
  AppBar,
  Box,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core'
import { useState } from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import LogoIcon from '../../icons/special/Logo'
import useStyles from './styles'
import { useAuth } from '../../modules/security/containers/AuthProvider'
import ProfileMenu from './ProfileMenu'
import { withUserPrivileges } from '../../modules/user/containers/UserProvider'
import { PRIVILEGES } from '../../modules/user/definitions'

function Layout(props) {
  const { route } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { logout } = useAuth()
  const { pathname } = useLocation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (pathname.includes('/app/games') || pathname.includes('/app/test-game')) {
    return renderRoutes(route.routes)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AppBar
          color="transparent"
          classes={{ root: classes.appbar }}
          component={Box}
          boxShadow={2}
        >
          <Toolbar>
            <Link to="/app">
              <LogoIcon width={130} height={50} />
            </Link>
            <div className={classes.actions}>
              <ProfileMenu className={classes.avatar} />
              <IconButton onClick={handleClick} color="inherit">
                <SettingsIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {withUserPrivileges(
            () => (
              <MenuItem
                onClick={handleClose}
                component={Link}
                to="/app/change-password"
              >
                تغيير كلمة المرور
              </MenuItem>
            ),
            [PRIVILEGES.internalLogin],
          )}

          {withUserPrivileges(
            () => (
              <MenuItem
                component={Link}
                onClick={handleClose}
                to="/app/profiles"
              >
                قائمة المستخدمين
              </MenuItem>
            ),
            [PRIVILEGES.subscribed],
          )}

          {withUserPrivileges(
            () => (
              <MenuItem
                component={Link}
                onClick={handleClose}
                to="/app/activity"
              >
                نشاطات المستخدم
              </MenuItem>
            ),
            [PRIVILEGES.subscribed],
          )}

          {withUserPrivileges(
            () => (
              <MenuItem
                component={Link}
                onClick={handleClose}
                to="/app/statistics"
              >
                إحصائيات المستخدم
              </MenuItem>
            ),
            [PRIVILEGES.subscribed],
          )}

          <MenuItem
            component={Link}
            onClick={handleClose}
            to="/app/subscriptions"
          >
            اشتراكاتي
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose()
              return logout()
            }}
          >
            خروج
          </MenuItem>
        </Menu>
      </div>

      <div className={classes.appBarSpacer} />

      <Container className={classes.content} fixed>
        {renderRoutes(route.routes)}
      </Container>
    </div>
  )
}

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  route: PropTypes.object.isRequired,
}

export default Layout
