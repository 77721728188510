const { CircularProgress, makeStyles } = require('@material-ui/core')

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
function FullLoading() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default FullLoading
