/* eslint-disable react/prop-types */
import { renderRoutes } from 'react-router-config'
import processRoutes from './helper'
import NotFound from '../pages/NotFoundPage'
import Firewall from '../modules/security/components/Firewall'
import Layout, { AdminLayout } from '../components/Layout'
import GameProvider from '../modules/game/containers/GameProvider'
import { PRIVILEGES } from '../modules/user/definitions'

const routes = [
  {
    component: Firewall,
    routes: [
      {
        path: '/app',
        component: Layout,
        isSecured: true,
        routes: [
          {
            path: '/',
            load: () =>
              import(/* webpackChunkName: "home" */ '../pages/HomePage'),
          },
          {
            path: '/cib-transaction-detail/:id',
            load: () =>
              import(/* webpackChunkName: "home" */ '../pages/Thanks'),
          },
          {
            path: '/profiles',
            component: ({ route }) => renderRoutes(route.routes),
            privileges: [PRIVILEGES.subscribed],
            routes: [
              {
                path: '/',
                load: () =>
                  import(
                    /* webpackChunkName: "profiles" */ '../pages/ProfilesPage'
                  ),
              },
              {
                path: '/add',
                load: () =>
                  import(
                    /* webpackChunkName: "profile-add" */ '../pages/ProfileHandlerPage'
                  ),
              },
              {
                path: '/:profileId',
                load: () =>
                  import(
                    /* webpackChunkName: "profile-view-edit" */ '../pages/ProfileHandlerPage'
                  ),
              },
            ],
          },
          {
            path: '/change-password',
            privileges: [PRIVILEGES.internalLogin],
            load: () =>
              import(
                /* webpackChunkName: "change-password" */ '../pages/ChangePasswordPage'
              ),
          },
          {
            path: '/subscriptions',
            load: () =>
              import(
                /* webpackChunkName: "subscriptions" */ '../pages/SubscriptionsPage'
              ),
          },
          {
            path: '/pricing',
            load: () =>
              import(
                /* webpackChunkName: "pricing container" */ '../pages/PricingContainer'
              ),
            routes: [
              {
                path: '/',
                load: () =>
                  import(
                    /* webpackChunkName: "pricing" */ '../pages/PricingPage'
                  ),
              },
              {
                path: '/bank/:packId',
                load: () =>
                  import(
                    /* webpackChunkName: "bank" */ '../pages/PricingBankPage'
                  ),
              },
              {
                path: '/e-payment-cib/:packId',
                load: () =>
                  import(
                    /* webpackChunkName: "bank" */ '../pages/PricingCibPage'
                  ),
              },
            ],
          },
          {
            path: '/games',
            component: ({ route }) => (
              <GameProvider>{renderRoutes(route.routes)}</GameProvider>
            ),
            routes: [
              {
                path: '/:gameId',
                load: () =>
                  import(
                    /* webpackChunkName: "game-page" */ '../pages/GamePage'
                  ),
              },
            ],
          },
          {
            path: '/activity',
            load: () =>
              import(
                /* webpackChunkName: "activity" */ '../pages/ActivityPage'
              ),
          },
          {
            path: '/statistics',
            load: () =>
              import(
                /* webpackChunkName: "statistics" */ '../pages/StatisticPage'
              ),
          },
        ],
      },
      {
        path: '/admin',
        component: AdminLayout,
        isSecured: true,
        routes: [
          {
            path: '/',
            load: () =>
              import(
                /* webpackChunkName: "change-password" */ '../pages/AdminHomePage'
              ),
          },
          {
            path: '/change-password',
            load: () =>
              import(
                /* webpackChunkName: "change-password" */ '../pages/ChangePasswordPage'
              ),
          },
        ],
      },
      {
        path: '/login',
        load: () =>
          import(/* webpackChunkName: "login-page" */ '../pages/LoginPage'),
      },
      {
        path: '/forget-password',
        load: () =>
          import(
            /* webpackChunkName: "forget-page" */ '../pages/ForgotPasswordPage'
          ),
      },
      {
        path: '/reset-password',
        load: () =>
          import(
            /* webpackChunkName: "reset-page" */ '../pages/ResetPasswordPage'
          ),
      },
    ],
  },
]

export default processRoutes({
  routes,
  notFoundComponent: NotFound,
})
